.searchWrap {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
}

.searchBar {
    width: 40% !important;
    height: 40px !important;
    border: 1px solid #979797;
    max-width: 421px;
}

.addNewBtn {
    width: fit-content !important;
    height: 40px !important;
    display: flex !important;
    align-items: center;
    border-radius: 0 !important;
    background-color: #5EB3E4 !important;
    padding: 0 20px !important;
}

.addNewBtn:hover {
    background-color: #4FA2CD !important;
}

.filtersSection {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 30px;
}

.dateSelectBtn {
    border-radius: 0 !important;
    margin-right: 20px !important;
}

.dateSelectText {
    font-size: 14px;
    color: #333333;
    border-bottom: 1px solid #333333;
    padding-bottom: 2px;
}

.calendar {
    width: 500px !important;
    padding: 20px 50px 0 30px !important;
}

.calendarTitle {
    color: #58B4E5;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
}

.dropDowns {
    background-color: #F4F6F7;
    color: #333333;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #333333;
    margin-right: 20px;
    cursor: pointer;
}

.dropDowns:focus{
    outline: none;
}

.resetButton {
    height: 25px;
    border: 2px solid #9b9658;
    border-radius: 5px;
    cursor: pointer;
}

.tableHeader {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1F764E;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.tableHeaderTitle {
    font-size: 16px;
    color: white;
}

.paginationContainer {
    margin-top: auto;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    user-select: none;
    background-color: white;
    padding: 0;
    color: #7d7a7a;
}

.paginationContainer > li {
    margin: 20px;
}

.paginationContainer > .previous, .paginationContainer > .next {
    font-size: 20px;
    padding: 5px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginationContainer > li > a {
    cursor: pointer;
    color: #7d7a7a;
}

.active {
    border: 2px solid #7d7a7a;
    border-radius: 50%;
    padding: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}