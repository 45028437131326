.content {
    padding-top: 20px;
    min-height: 100px;
    display: flex;
    /* justify-content: space-between; */
}

.center {
    text-align: end;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.font {
    font-size: 16px;
    line-height: 36px;
    margin-left: 20px;
}

.filterIcon {
    color: rgb(88, 180, 229);
    margin-top: 16px;
    width: 37px !important;
    height: 36px !important;
}

.filterIcon:hover {
    cursor: pointer;
    color: white;
    background-color: #4FA2CD;
}


.multiSelectFields {
    width: 100% !important;
    /* max-width: 500px !important; */
    padding-left: 20px !important;
    /* padding-right: 20px !important; */
    z-index: 3000;
}

.filtersOpen {
    max-height: 200px;
    width: 100%;
    transition: max-height 0.5s ease;
    overflow: visible;
}

.filtersClosed {
    max-height: 0;
    transition: max-height 0.5s ease;
    overflow: hidden;
}

.cultureDropdown {
    font-size: 16px;
    z-index: 14000;
    margin-right: 20px;
    color: #333333;
    border: none;
    cursor: pointer;
    border-bottom: 1px solid #333333;
    max-height: 200px;
}

.cultureDropdown:focus {
    outline: none;
}

.hybridDropdown {
    font-size: 16px;
    z-index: 14000;
    color: #333333;
    border: none;
    cursor: pointer;
    border-bottom: 1px solid #333333;   
}

.hybridDropdown:focus {
    outline: none;
}

.allFieldsContainer {
    display: flex;
    flex-wrap: wrap;
    min-height: 400px;
    align-content: flex-start;
    margin-bottom: 50px;
}

.fieldLabel {
    height: 24px;
    padding: 2px 5px;
    border: 1px solid gray;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin: 5px;
}

.fieldDelete {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fieldDelete:hover {
    background-color: #58B4E5;
    color: white;
}

.deleteIcon {
    width: 15px;
    height: 15px;
    color: #58B4E5;
}


.fieldDelete:hover .deleteIcon {
    color: white;
}