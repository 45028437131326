.rdrDefinedRangesWrapper {
    width: 30%;
}

.rdrInputRange {
    display: none;
}

.rdrDateRangeWrapper {
    width: 70%;
}

.rdrMonthAndYearWrapper, .rdrMonth {
    width: 100%;
}

.rdrNextPrevButton {
    margin: 0 auto;
}

.rdrMonthPicker, .rdrYearPicker {
    margin: 0 auto;
}

.rdrMonthAndYearPickers select {
    padding: 10px 20px 10px 10px;
}