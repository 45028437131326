.arrange_seedlings_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px;
    min-height: 70vh;

}

.custom_number_field {
    font-size: 15px;
    width: 80px;
    margin-top: 15px;
    margin-left: 16px;
    padding-bottom: 4px;
    text-align: center;
}

.rows_sorts_tables {
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 50px;
    grid-row-gap: .5em;
    grid-column-gap: 1em;

    table,
    th,
    td {
        border-collapse: collapse;
    }

    td,
    th {
        height: 20px;
    }

    th {
        font-size: 16px;
        font-weight: 550;
    }
}

.rows_seedlings_arrange_button {
    text-align: right;
}

.checkboxWrapper {
    padding: 8px;
}

.checkbox {
    width: 20px;
    height: 20px;
}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom_checkbox {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 2px;
    border: 2px solid rgba(0, 0, 0, 0.54);
    cursor: pointer;
}

/* When the checkbox is checked, add a green background */
input[type="checkbox"]:checked+.custom_checkbox {
    background-color: #04764e;
    border-color: #04764e;
}

.custom_checkbox::after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 0;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

input[type="checkbox"]:checked+.custom_checkbox::after {
    display: block;
}