.page {
    width: 100%;
}

.card {
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: #fff;
    width: auto;
    margin: 30px 40px 40px 40px;
}

.back_container {
    display: flex;
    padding: 30px 0px 0px 0px;
    cursor: pointer;
    margin-left: 40px;
    user-select: none;
    width: fit-content;
}

.back {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #333;
    line-height: 24px;
    cursor: pointer;
    user-select: none;
}

.title {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #333;
    line-height: 24px;
    user-select: none;
    text-align: center;
    margin-bottom: 0px;
    background: #058457;
    color: #fff;
    padding: 24px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.sections_container {
    padding: 40px;
}

.section {
    margin: 0 20px 40px 0;
}

.title_container {
    display: flex;
    justify-content: space-between;
}

.title_left {
    display: flex;
    margin: 0 8px;
}

.title_icon {
    user-select: none;
}

.section_title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #333;
    line-height: 26px;
    margin: 0px 15px;
}

.add_button {
    height: 26px;
    width: 26px;
    background-color: #58B4E5;
    border-radius: 50%;
    box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
    user-select: none;
    cursor: pointer;
}

.add_button:hover {
    background-color: #4FA2CD;
    transition: background-color 0.3s;
}

.add_icon {
    margin: 4px;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(100%) contrast(100%);
    user-select: none;
}

.add_icon_menu {
    margin: 4px;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(100%) contrast(100%);
    user-select: none;
    pointer-events: none;
    margin-top: 16px;
    padding-left: 2px;
}

.edit_icon {
    user-select: none;
    cursor: pointer;
    margin: 0 8px;
}

.content_container {
    margin-top: 8px;
}

.content_border {
    background: #126038;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}

.content_empty {
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: -0.27px;
    color: rgb(83, 83, 83);
    margin: 0 8px;
}

.operation_title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #333;
    line-height: 26px;
    margin: 0px 8px 30px 8px;
}

.working_operation_color {
    margin: 14px 8px 0px 8px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.3px;
    color: #58B4E5;
}

.working_operation {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.27px;
    color: rgb(39, 39, 39);
    margin: 2px 8px 0px 8px;
}

.content_flex {
    display: flex;
}

.content_between {
    display: flex;
    justify-content: space-between;
}

.table {
    width: 100%;
    border-collapse: collapse;
    display: table;
}

.table_header {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #58B4E5;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    width: fit-content;
}

.table_row {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    display: table-row;
}

.table_border {
    background: #9c9c9c;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}

.table_header_end {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #58B4E5;
    text-align: end;
    width: 50%;
    padding: 8px 8px 8px 0px;
    display: table-cell;
}

.table_header_icon {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #58B4E5;
    text-align: end;
    /* width: 50%; */
    padding: 8px 8px 8px 0px;
    display: table-cell;
    width: 46px;
}

.table_header_end_mat {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #58B4E5;
    text-align: end;
    width: 60%;
    padding: 8px 8px 8px 0px;
    display: table-cell;
}

.table_row_text {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #333;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    min-width: 200px;
}

.table_row_icon {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #333;
    text-align: end;
    /* padding: 8px 0px 8px 8px; */
    display: table-cell;
    padding-right: 9px;
    width: 46px;
}

.table_row_end {
    width: 50%;
    text-align: end;
    padding-right: 8px;
    display: table-cell;
}

.table_row_end_mat {
    width: 60%;
    text-align: end;
    padding-right: 14px;
    display: table-cell;
}

.table_row_odd {
    border-bottom: solid 1px rgba(0, 0, 0, .05);
    background: #f8f8f8;
    display: table-row;
}

.table_body {
    display: block;
    overflow: auto;
    height: 300px;
    width: 100%;
}

.table_head {
    display: table-header-group;
    vertical-align: middle;
    overflow: hidden;
}

.table_row_header {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    display: block;
}

.table_div {
    overflow-y: auto;
    max-height: 260px;
    transition: height 0.2s;
}

.delete_button {
    margin-top: 14px;
    cursor: pointer;
    user-select: none;
    margin-right: 5px;
}

.delete_button_table {
    margin-top: 4px;
    cursor: pointer;
    user-select: none;
    margin-right: 4px;
    padding-left: 10px;
}

.table_row_text_bold {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #333;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    font-weight: 500;
}

.table_row_text_bold_right {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #333;
    text-align: right;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    font-weight: 500;
}


.save_button {
    height: 40px;
    font-size: 20px;
    color: white;
    width: 120px;
    background-color: #58B4E5;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
    user-select: none;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    margin: 36px 10px 6px 10px;
}

.save_button:hover {
    background-color: #4FA2CD;
    transition: background-color 0.3s;
}

.save_button_disabled {
    height: 40px;
    font-size: 20px;
    color: white;
    width: 120px;
    background-color: #a5a5a5;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
    user-select: none;
    line-height: 40px;
    text-align: center;
    margin: 36px 10px 6px 10px;
}

.pick_rows_button {
    padding: 8px;
    font-size: 12px;
    font-weight: bold;
    color: rgb(34, 34, 34);
    border: 1px solid rgb(204, 204, 204);
    background: #E6E6E6;
    cursor: pointer;
    border-radius: 4px;
}

.pick_rows_button:disabled {
    cursor: not-allowed;
}

.pick_rows_button:hover {
    border-color: rgb(179, 179, 179);
}

.pick_rows_button::first-letter {
    text-transform: capitalize;
}