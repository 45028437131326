.arrange_seedlings_button {
    margin-left: 32px;
    margin-top: 30px;
    padding: 8px;
    font-size: 12px;
    font-weight: 400;
    color: rgb(34, 34, 34);
    border: 1px solid rgb(204, 204, 204);
    background: #E6E6E6;
    cursor: pointer;
    border-radius: 4px;
}

.arrange_seedlings_button:disabled {
    cursor: not-allowed;
}

.arrange_seedlings_button:hover {
    border-color: rgb(179, 179, 179);
}

.arrange_seedlings_button::first-letter {
    text-transform: capitalize;
}


.arrange_seedlings_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px;
    min-height: 70vh;

}

.custom_number_field {
    font-size: 15px;
    width: 80px;
    margin-top: 15px;
    margin-left: 16px;
    padding-bottom: 4px;
    text-align: center;
}

.rows_seedlings_tables {
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 50px;
    grid-row-gap: .5em;
    grid-column-gap: 1em;

    table,
    th,
    td {
        border-collapse: collapse;
    }

    td,
    th {
        height: 20px;
    }

    th {
        font-size: 16px;
        font-weight: 550;
    }
}

.rows_seedlings_arrange_button {
    text-align: right;
}

.crop_select_wrapper {
    padding: 24px 18px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    background: #FDFDFD;
}

.select-wrapper {
    select {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }   
}